import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Paper from '@mui/material/Paper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

export default function DatePickerDialog({ date, setDate }) {

    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleClose = () => {
        setDate(prevState => ({
            ...prevState,  // This spreads out the current state
            open: false     // This updates the 'open' property
        }));
    };

    const handleDateChange = (newValue) => {
        setSelectedDate(newValue);
    };

    const handleLoad = (date) => {
        // Assuming setDate and startdate are in scope or accessible
        const unixTimestamp = date.startdate;

        // Convert the Unix timestamp to a JavaScript Date
        const jsDate = new Date(unixTimestamp * 1000); // Multiplying by 1000 to convert seconds to milliseconds

        // Convert the JavaScript Date to a dayjs object (if needed)
        const dayjsDate = dayjs(jsDate);
        // Set the dayjsDate object to your date picker
        setSelectedDate(dayjsDate);

        // Assuming you have a method to update the state or prop of your date picker, named setDatePickerValue
    };

    const handleSave = () => {
        // Convert selectedDate to a standard JavaScript Date if it's a dayjs object
        const jsDate = selectedDate.toDate ? selectedDate.toDate() : selectedDate;

        // 1. Convert jsDate to Unix timestamp and save in startDate
        const startDate = Math.floor(jsDate.getTime() / 1000); // Dividing by 1000 to convert milliseconds to seconds

        // 2. Add 24 hours, convert to timestamp, and save as endDate
        const addedDate = selectedDate.add ? selectedDate.add(24, 'hour').toDate() : new Date(jsDate.getTime() + 24 * 60 * 60 * 1000);
        const endDate = Math.floor(addedDate.getTime() / 1000);

        // console.log('Start Date (Unix Timestamp):', startDate);
        // console.log('End Date (Unix Timestamp):', endDate);
        setDate({
            open: false,
            startdate: startDate,
            enddate: endDate
        })

    };

    useEffect(() => {
        handleLoad(date)
    }, [date]);

    return (
        <Paper sx={{
            position: 'absolute',
            top: 100,
            right: 10,
            padding: 0,
            zIndex: 950
        }}>
            <Dialog open={date.open} onClose={handleClose}>
                <DialogTitle>Select a Date</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            inputFormat="MM/dd/yyyy"
                            value={selectedDate}
                            onChange={handleDateChange}
                        />
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
}
