import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';

export default function DatePickerDialog({ help, setHelp }) {

    const handleClose = () => {
        setHelp(prevState => !prevState); // toggling based on the previous state
    };

    return (
        <Paper sx={{
            position: 'absolute',
            top: 100,
            right: 10,
            padding: 0,
            zIndex: 950
        }}>
            <Dialog open={help} onClose={handleClose}>
                <DialogTitle>Help</DialogTitle>
                <DialogContent>
                    <ListItem>
                        <ListItemIcon>
                        <div style={{ whiteSpace: 'nowrap' }}><i className="fas fa-plus"></i> <i className="fas fa-minus"></i></div>
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1">Zoom Controls</Typography>
                        </ListItemText>
                    </ListItem>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <i className="fas fa-sync-alt"></i>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body1">Toggles automatic updates.</Typography>
                            </ListItemText>
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                                <i className="fas fa-calendar-day"></i>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body1">Date Picker to choose a specific date.</Typography>
                            </ListItemText>
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                                <i className="fas fa-ship"></i>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body1">Zoom into the latest boat position.</Typography>
                            </ListItemText>
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                                <i className="fas fa-map"></i>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body1">Center the map to the boat's position with a broader view.</Typography>
                            </ListItemText>
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                                <i className="fas fa-map-marker"></i>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body1">Toggle display of waypoints on the map.</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
}
