// MapComponent.js
import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import DataOverlay from './DataOverlay'; // Import the overlay component
import DatePickerDialog from './DatePickerDialog'; // Import the overlay component
import HelpDialog from './HelpDialog'

function getCardinalDirection(angle) {
    var directions = ['North', 'North-East', 'East', 'South-East', 'South', 'South-West', 'West', 'North-West', 'North'];
    return directions[Math.round(((angle %= 360) < 0 ? angle + 360 : angle) / 45)];
}


export default function MapComponent({ data }) {
    const mapRef = useRef(null);
    const waypointLayerGroup = useRef(L.layerGroup()).current;
    const [latestDataPoint, setLatestDataPoint] = useState(null);
    const latestDataPointRef = useRef();
    const [mapInstance, setMapInstance] = useState(null);
    const [date, setDate] = useState({
        open: false,
        startdate: '0000',
        enddate: '0000',

    });

    const [fetchedData, setFetchedData] = useState(null);
    const [autoUpdate, setAutoUpdate] = useState(true);
    const [help, setHelp] = useState(false);


    async function fetchData(date) {
        let gpsURL
        try {
            gpsURL = 'https://inluck.ca/fetchData.php?starttime=' + date.startdate + '&endtime=' + date.enddate
            const response = await fetch(gpsURL);
            const data = await response.json();
            setFetchedData(data);  // Store the fetched data in state
        } catch (error) {
            console.error("Failed to fetch data:", error);
        }
    }

    useEffect(() => {
        const today = new Date();

        // Reset the time to the start of the day
        today.setHours(0, 0, 0, 0);

        // Start of the day in Unix timestamp
        const startTimestamp = today.getTime();

        // End of the day in Unix timestamp
        const endTimestamp = startTimestamp + (24 * 60 * 60 * 1000);  // 24 hours in milliseconds

        setDate({
            open: false,
            startdate: Math.round(startTimestamp / 1000),
            enddate: Math.round(endTimestamp / 1000),
        });
    }, []);

    useEffect(() => {
        if (date.startdate != '0000') {
            fetchData(date);
        }

        // Start an interval to fetch data every 60 seconds
        const intervalId = setInterval(() => {
            if (autoUpdate == true) {
                fetchData(date);
            }
        }, 60000);  // 60000 milliseconds = 60 seconds

        // Cleanup function to clear the interval when the component is unmounted
        return () => clearInterval(intervalId);

    }, [date, autoUpdate]);



    useEffect(() => {
        let map;

        if (!mapInstance) {
            map = L.map(mapRef.current).setView([47.5486, -53.127486], 11);  // Default view, you can adjust as needed

            L.Control.AutoUpdateControl = L.Control.extend({
                options: {
                    position: 'topleft',
                    onAutoUpdateChange: null, // Callback when the button is clicked
                    autoUpdate: false // Default value, can be updated when creating the control
                },

                onAdd: function (map) {
                    const controlContainer = L.DomUtil.create('div', 'leaflet-bar');
                    const controlButton = L.DomUtil.create('a', 'date-picker-button', controlContainer);

                    controlButton.title = "Auto Update";
                    controlButton.href = "#";
                    controlButton.innerHTML = '<i class="fas fa-sync-alt"></i>';

                    // Set initial background based on autoUpdate
                    controlButton.style.background = this.options.autoUpdate ? '#D1FFBD' : '';

                    // Add the event listener to the button
                    L.DomEvent.on(controlButton, 'click', (e) => {
                        L.DomEvent.stopPropagation(e);
                        L.DomEvent.preventDefault(e);

                        // Propagate the change to React
                        if (this.options.onAutoUpdateChange) {
                            this.options.onAutoUpdateChange();
                        }

                        // Update button background
                        controlButton.style.background = this.options.autoUpdate ? '' : '#D1FFBD';
                        this.options.autoUpdate = !this.options.autoUpdate;
                    });

                    return controlContainer;
                }
            });

            map.addControl(new L.Control.AutoUpdateControl({
                onAutoUpdateChange: () => setAutoUpdate(prev => !prev),
                autoUpdate
            }));


            L.Control.DatePickerControl = L.Control.extend({
                options: {
                    position: 'topleft'
                },

                onAdd: function (map) {
                    const controlContainer = L.DomUtil.create('div', 'leaflet-bar');
                    const controlButton = L.DomUtil.create('a', 'date-picker-button', controlContainer);

                    controlButton.title = "Open Date Picker";
                    controlButton.href = "#";
                    controlButton.innerHTML = '<i class="fas fa-calendar-day"></i>';  // Using FontAwesome map-marker icon

                    // Add the event listener to the button
                    L.DomEvent.on(controlButton, 'click', function (e) {
                        L.DomEvent.stopPropagation(e);
                        L.DomEvent.preventDefault(e);
                        setDate(prevState => ({
                            ...prevState,  // This spreads out the current state
                            open: true     // This updates the 'open' property
                        }));
                    });

                    return controlContainer;
                }
            });

            map.addControl(new L.Control.DatePickerControl());



            L.Control.ZoomOnBoat = L.Control.extend({
                options: {
                    position: 'topleft'
                },

                onAdd: function (map) {
                    var controlContainer = L.DomUtil.create('div', 'leaflet-bar');
                    var controlButton = L.DomUtil.create('a', 'waypoints-toggle-button', controlContainer);

                    controlButton.title = "Zoom On Boat";
                    controlButton.href = "#";
                    controlButton.innerHTML = '<i class="fas fa-ship"></i>';  // Using FontAwesome map-marker icon

                    // Add the event listener to the button
                    L.DomEvent.on(controlButton, 'click', function (e) {
                        L.DomEvent.stopPropagation(e);
                        L.DomEvent.preventDefault(e);
                        map.setView(latestDataPointRef.current.location, 20);
                    });

                    return controlContainer;
                }
            });

            map.addControl(new L.Control.ZoomOnBoat());

            L.Control.CentreOnBoat = L.Control.extend({
                options: {
                    position: 'topleft'
                },

                onAdd: function (map) {
                    var controlContainer = L.DomUtil.create('div', 'leaflet-bar');
                    var controlButton = L.DomUtil.create('a', 'waypoints-toggle-button', controlContainer);

                    controlButton.title = "Show Map";
                    controlButton.href = "#";
                    controlButton.innerHTML = '<i class="fas fa-map"></i>';  // Using FontAwesome map-marker icon

                    // Add the event listener to the button
                    L.DomEvent.on(controlButton, 'click', function (e) {
                        L.DomEvent.stopPropagation(e);
                        L.DomEvent.preventDefault(e);
                        map.setView(latestDataPointRef.current.location, 10);
                    });

                    return controlContainer;
                }
            });

            map.addControl(new L.Control.CentreOnBoat());

            L.Control.WaypointsToggle = L.Control.extend({
                options: {
                    position: 'topleft'
                },

                onAdd: function (map) {
                    var controlContainer = L.DomUtil.create('div', 'leaflet-bar');
                    var controlButton = L.DomUtil.create('a', 'waypoints-toggle-button', controlContainer);

                    controlButton.title = "Toggle Waypoints";
                    controlButton.href = "#";
                    controlButton.innerHTML = '<i class="fas fa-map-marker"></i>';  // Using FontAwesome map-marker icon

                    // Add the event listener to the button
                    L.DomEvent.on(controlButton, 'click', function (e) {
                        L.DomEvent.stopPropagation(e);
                        L.DomEvent.preventDefault(e);
                        if (map.hasLayer(waypointLayerGroup)) {
                            map.removeLayer(waypointLayerGroup);
                        } else {
                            waypointLayerGroup.addTo(map);
                        }
                    });

                    return controlContainer;
                }
            });

            map.addControl(new L.Control.WaypointsToggle());

            L.Control.HelpControl = L.Control.extend({
                options: {
                    position: 'topleft'
                },

                onAdd: function (map) {
                    const controlContainer = L.DomUtil.create('div', 'leaflet-bar');
                    const controlButton = L.DomUtil.create('a', 'date-picker-button', controlContainer);

                    controlButton.title = "Open Help";
                    controlButton.href = "#";
                    controlButton.innerHTML = '<i class="fas fa-question"></i>';  // Using FontAwesome map-marker icon

                    // Add the event listener to the button
                    L.DomEvent.on(controlButton, 'click', function (e) {
                        L.DomEvent.stopPropagation(e);
                        L.DomEvent.preventDefault(e);
                        // Here, we need to open the MUI Date Picker
                        // We will handle this in the React component
                        // map.fire('openDatePicker');
                        setHelp(prevState => !prevState); // toggling based on the previous state

                    });

                    return controlContainer;
                }
            });

            map.addControl(new L.Control.HelpControl());



            setMapInstance(map);
        } else {
            map = mapInstance;
            waypointLayerGroup.clearLayers();
            map.eachLayer(function (layer) {
                map.removeLayer(layer);
            });
        }
        L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
            subdomains: 'abcd',
            maxZoom: 19
        }).addTo(map);


        var colorIndex = 0;
        var colors = ['red', 'blue', 'green', 'yellow', 'orange', 'violet', 'gold', 'grey', 'black'];

        var lastWaypointIcon = L.icon({
            iconUrl: 'boat.png', // replace with the actual path to your boat icon image
            iconSize: [48, 48],
            iconAnchor: [24, 48],
            popupAnchor: [0, -48]
        });

        try {
            for (var trackId in fetchedData.info) {
                var trackData = fetchedData.info[trackId];
                var currentColor = colors[colorIndex];
                var latlngs = [];  // to hold our coordinate pairs for the polyline

                trackData.forEach(function (point, index) {
                    var locationRaw = point.location.split(',').map(Number);
                    var location = [locationRaw[0], locationRaw[1]];
                    latlngs.push(location);
                    // convert lat and long into correct order
                    point.location = location
                    // Convert speed from mph to knots
                    point.speed = point.speed * 0.868976;
                    point.locatetime = Number(point.locatetime)

                    // Convert the timestamp to a human-readable date and time
                    var date = new Date(point.locatetime);
                    point.dateString = date.toLocaleString();

                    // Convert the direction from degrees to cardinal directions
                    point.cardinalDirection = getCardinalDirection(point.direction);


                    var iconToUse = new L.Icon({
                        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-' + currentColor + '.png',
                        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                        iconSize: [5, 8],
                        iconAnchor: [3, 8],
                        popupAnchor: [1, -34],
                        shadowSize: [5, 8]
                    });

                    // Check if it's the last track and the last waypoint
                    if (index === trackData.length - 1 && trackId === Object.keys(fetchedData.info)[Object.keys(fetchedData.info).length - 1]) {
                        iconToUse = lastWaypointIcon;
                        if (point.speed > 2) {
                            if (getCardinalDirection(point.direction)[0] === "N") {
                                var lat = point.location[0] - 0.0755
                                var long = point.location[1]
                                map.setView([lat, long], 11);  // Default view, you can adjust as needed
                            } else if (getCardinalDirection(point.direction)[0] === "S") {
                                var lat = point.location[0] + 0.0755
                                var long = point.location[1]
                                map.setView([lat, long], 11);  // Default view, you can adjust as needed
                            } else {
                                map.setView(point.location, 11);  // Default view, you can adjust as needed
                            }
                        } else {
                            map.setView(point.location, 11);  // Default view, you can adjust as needed
                        }
                        const API_KEY = '6a0bf6a27ab50eab0cc9539af86f7a55'; // Replace with your API key
                        const endpoint = `https://api.openweathermap.org/data/2.5/weather?lat=${point.location[0]}&lon=${point.location[1]}&appid=${API_KEY}&units=metric`;

                        async function fetchWeatherData() {

                            try {
                                const response = await fetch(endpoint);
                                const result = await response.json();

                                // console.log(point)
                                point.conditions = result;
                                setLatestDataPoint(point)
                                // Extract and use the required data as needed
                            } catch (error) {
                                console.error("Failed to fetch weather data:", error);
                            }
                        }

                        fetchWeatherData()
                        // console.log(point.weather)

                        latestDataPointRef.current = point;

                    }


                    // Create a marker for each point with popup information
                    var marker = L.marker(location, { icon: iconToUse }).bindPopup('<strong>Update Received:</strong> ' + point.dateString + '<br/>' + '<strong>Speed Over Ground:</strong> ' + point.speed.toFixed(2) + ' knots<br>' + '<strong>Direction:</strong> ' + point.cardinalDirection + ' ' + point.direction + '°');

                    // Add the marker to the layer group
                    waypointLayerGroup.addLayer(marker);
                });

                // Create the polyline for this track with its designated color
                L.polyline(latlngs, { color: currentColor }).addTo(map);

                colorIndex = (colorIndex + 1) % colors.length; // loop through the colors
            }
        } catch {
        }
        waypointLayerGroup.addTo(map); // Add the waypoint layer group to the map
    }, [fetchedData]);

    return (
        <div ref={mapRef} style={{ height: "100%", width: "100%" }}>
            {latestDataPoint && <DataOverlay latestData={latestDataPoint} />}
            <DatePickerDialog date={date} setDate={setDate} />
            <HelpDialog help={help} setHelp={setHelp} />

        </div>
    );
}
