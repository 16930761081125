// App.js
import React, { useEffect, useRef, useState } from 'react';
import MapComponent from './MapComponent';
import { Container, CssBaseline, AppBar, Toolbar, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#333333', // This sets the primary color to dark gray
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif', // Set the default font family to Roboto, falling back to Arial and sans-serif if Roboto is not available
  },
});

function App() {

  return (
    <ThemeProvider theme={darkTheme}>

      <Container maxWidth={false} style={{ padding: 0, display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <CssBaseline />
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6">Happy Puppy</Typography>
          </Toolbar>
        </AppBar>
        <MapComponent
        />
      </Container>
    </ThemeProvider>

  );
}

export default App;
