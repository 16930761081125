import React, { useState } from 'react';
import { Paper, Typography } from '@mui/material';

function getCardinalDirection(angle) {
    var directions = ['North', 'North-East', 'East', 'South-East', 'South', 'South-West', 'West', 'North-West', 'North'];
    return directions[Math.round(((angle %= 360) < 0 ? angle + 360 : angle) / 45)];
}

const DataOverlay = ({ latestData }) => {
    // Extract relevant data from the latestData prop
    const [isTextVisible, setIsTextVisible] = useState(true);

    const { weather, main, wind } = latestData;
    const iconURL = `https://openweathermap.org/img/w/${latestData.conditions.weather[0].icon}.png`;
    const ventuskyURL = `https://www.ventusky.com/?p=${latestData.location[0]};${latestData.location[1]};7&l=temperature-2m`

    const toggleVisibility = () => {
        setIsTextVisible(!isTextVisible);
    };


    return (
        <Paper sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            padding: 1,
            zIndex: 900,
            overflow: 'hidden',  // Ensure the icon does not spill outside the container
            relative: true, // Set position relative so the icon can be positioned absolute inside
            background: `url(${iconURL}) no-repeat bottom right`, // set the icon as background image
            backgroundColor: 'rgba(211, 211, 211, 0.8)',  // lightgrey with 50% opacity
            backgroundSize: '50px 50px',  // size of the icon
        }}
            onClick={toggleVisibility}
        >
            {isTextVisible && (
                <>
                    <Typography variant="body2">
                        <strong>Last Update:</strong> {new Date(latestData.locatetime).toLocaleString()}
                    </Typography>
                    <Typography variant="body2">
                        <strong>Current Speed:</strong> {latestData.speed} knots
                    </Typography>
                    <Typography variant="body2">
                        <strong>Direction:</strong> {latestData.cardinalDirection}  {latestData.direction}°
                    </Typography>
                    <Typography variant="body2">
                        <strong>Coordinates:</strong> {latestData.location[0]}, {latestData.location[1]}
                    </Typography>
                    <hr />
                    <Typography variant="body2">
                        <strong>Weather:</strong> <a href={ventuskyURL} target="_new"> {latestData.conditions.name} - {latestData.conditions.weather[0].description}</a>
                    </Typography>
                    <Typography variant="body2">
                        <strong>Last Update:</strong> {new Date(latestData.conditions.dt * 1000).toLocaleString()}
                    </Typography>
                    <Typography variant="body2">
                        <strong>Temperature:</strong> {latestData.conditions.main.temp}°C <strong>Feels-like:</strong> {latestData.conditions.main.feels_like}°C
                    </Typography>
                    <Typography variant="body2">
                        <strong>Pressure:</strong> {latestData.conditions.main.pressure} hPa <strong>Humidity:</strong> {latestData.conditions.main.humidity}%
                    </Typography>
                    <Typography variant="body2">
                        <strong>Wind Speed:</strong> {latestData.conditions.wind.speed} knots {latestData.conditions.wind.deg}°
                    </Typography>
                    <Typography variant="body2">
                        <strong>Wind Gust:</strong> {latestData.conditions.wind.gust || 0} knots {getCardinalDirection(latestData.conditions.wind.deg)}
                    </Typography>
                </>
            )}
        </Paper>
    );
};

export default DataOverlay;